import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const minDeltaMs = 5000;
const defaultDeltaMs = 15000;
const defaultCapacity = 120;

function traffic(x, red, yellow, green) {
  if (x === undefined) return "";
  // interpolates range to hue color for status
  // https://mathworld.wolfram.com/LagrangeInterpolatingPolynomial.html
  if (green === undefined) green = 0;

  const reversed = green > red;
  if ((!reversed && x < green) || (reversed && x > green))
    return "mediumseagreen";
  if ((!reversed && x > red) || (reversed && x < red)) return "crimson";

  if (yellow === undefined) yellow = (green + red) / 2;

  const xs = [red, yellow, green];
  const ys = [0, 60, 120];
  // sum from j to n
  let hue = xs.reduce(
    (sum, xj, j) =>
      sum +
      // P_j = y_j *
      ys[j] *
        // product from k to n where j!=k
        xs
          .filter((_, k) => j !== k)
          .reduce((prod, xk) => (prod * (x - xk)) / (xj - xk), 1),
    0
  );
  if (hue < 0) hue = 0;
  if (hue > 120) hue = 120;
  // Return a CSS HSL string
  return `hsl(${Math.round(hue)},50%,50%)`;
}

let localRegions;
try {
  localRegions = JSON.parse(localStorage.getItem("e2-regions") ?? "[]").reduce(
    (regions, { key, lastrun, ...region }) => {
      regions[key] = {
        key,
        lastrun: new Date(lastrun),
        ...region,
        history: JSON.parse(
          localStorage.getItem(`e2-region-data-${key}`) ?? "[]"
        ).reduce((history, { time, reqcount, avgtime }) => {
          time = new Date(time);
          if (+time && reqcount >= 0 && avgtime >= 0)
            history[minDeltaMs * Math.trunc(+time / minDeltaMs)] = {
              time,
              reqcount,
              avgtime,
              color: traffic(avgtime, 10000, 2000)
            };
          return history;
        }, {})
      };
      return regions;
    },
    {}
  );
} catch (e) {
  localRegions = {};
}

export default new Vuex.Store({
  state: {
    init: true,
    regions: localRegions,
    overall: {},
    update_pump: undefined,
    saas: false,
    apiUrl: "/api/",
    deltaMs: defaultDeltaMs,
    capacity: defaultCapacity
  },
  actions: {
    async Init({ dispatch, state }) {
      state.init = true;
      await dispatch("Regions");

      // setup polling timers
      clearInterval(state.update_pump);
      const now = +new Date();
      const target_time = state.deltaMs * (1 + Math.trunc(now / state.deltaMs));
      const trim = (now % minDeltaMs) / 2 + minDeltaMs / 3; // try to line up requests without completely overlapping
      const wait = trim + target_time - now;
      await new Promise(resolve => setTimeout(resolve, wait)); // sleep
      dispatch("Regions");
      state.update_pump = setInterval(() => dispatch("Regions"), state.deltaMs);
      state.init = false;
    },
    async Regions({ state: { apiUrl }, commit, dispatch }) {
      // get region info
      const { data } = await axios.get(apiUrl + "getRegionData");
      if (data) commit("Regions", { data });
      await Promise.all(
        data.map(async ({ key }) => await dispatch("Current", { key }))
      );
      commit("Save");
    },
    async Current({ state: { apiUrl }, commit, dispatch }, { key }) {
      // get all current info for each region
      const { data } = await axios.get(
        `${apiUrl}getStatusData?v=2&region=${key}`
      );
      commit("Current", { key, data });
      await dispatch("History", { key });
    },
    async History(
      { state: { apiUrl, regions, capacity, deltaMs, init }, commit },
      { key }
    ) {
      const history = regions[key].history;
      let missing = [];
      let checks;
      if (!init)
        // exponential backoff
        checks = Array.from(
          Array(Math.trunc(Math.log2(capacity) + 1)).keys()
        ).map(i => 2 ** i);
      else checks = Array.from(Array(capacity).keys());
      const now = deltaMs * Math.trunc(+new Date() / deltaMs);
      checks.forEach(i => {
        if (history[now - i * deltaMs] === undefined)
          missing.push(new Date(now - i * deltaMs));
      });
      // get any missing data
      if (!missing?.length) return;
      let { data } = await axios.post(
        `${apiUrl}getHistoryData?region=${key}`,
        missing
      );
      data = data
        .map(({ avgtime, reqcount, time }) =>
          avgtime >= 0 && reqcount >= 0
            ? { avgtime, reqcount, time }
            : undefined
        )
        .filter(x => x);
      if (data?.length) commit("History", { key, data });
    },
    async DeltaMs({ commit, dispatch }, value) {
      commit("Save");
      commit("DeltaMs", value);
      await dispatch("Init");
    }
  },
  mutations: {
    Save({ regions }) {
      // only save timestamps that users see
      const earliest = +new Date() - defaultDeltaMs * defaultCapacity;
      localStorage.setItem(
        "e2-regions",
        JSON.stringify(
          Object.entries(regions)
            .map(([key, { history, ...region }]) => {
              localStorage.setItem(
                `e2-region-data-${key}`,
                JSON.stringify(
                  Object.entries(history)
                    .map(([_, { time, reqcount, avgtime }]) => {
                      if (time >= earliest)
                        return {
                          time,
                          reqcount,
                          avgtime
                        };
                    })
                    .filter(x => x)
                )
              );
              return region;
            })
            .filter(x => x)
        )
      );
    },
    Trim({ regions, capacity, saas }) {
      Object.entries(regions).forEach(({ key, region }) => {
        let keys = Object.keys(region.history);
        // no reason to sort all the time
        if (keys.length < 2 * capacity || saas) return;
        keys.sort();
        keys = keys.slice(0, Math.max(keys.length - capacity, 0));
        keys.forEach(k => Vue.delete(regions[key].history, k));
      });
    },
    Regions(state, { data }) {
      Vue.set(
        state,
        "regions",
        data.reduce((obj, region) => {
          if (region?.key)
            obj[region.key] = {
              history: {},
              ...(state.regions[region.key] ?? {}),
              ...region
            };
          return obj;
        }, {})
      );
    },
    Current({ regions }, { key, data }) {
      let { stats, intstats, errstats } = data;
      let { reqcount, errcount, avgtime, lastrun } =
        intstats?.find(r => r?.region === key) ?? {};

      stats = stats?.filter(elem => elem?.target?.startsWith(key));
      let region = stats.reduce(
        (
          { up, down, latency, maintenance, e2color },
          { CountUp, CountDown, AvgLatency, LastStatus, "e2-color": E2Color }
        ) => ({
          up: up + CountUp || 0,
          down: down + CountDown || 0,
          latency: latency + AvgLatency || 0,
          maintenance:
            maintenance || LastStatus?.toLowerCase() === "maintenance",
          e2color: e2color || E2Color
        }),
        {
          up: 0,
          down: 0,
          latency: 0
        }
      );

      lastrun = new Date(lastrun + "Z");
      if (!+lastrun) lastrun = new Date();
      region = {
        ...regions[key],
        ...region,
        reqcount,
        errcount,
        errstats,
        avgtime,
        lastrun
      };

      region.latency /= stats.length;
      if (region.reqcount) region.errrate = region.errcount / region.reqcount;

      if (avgtime >= 0 && reqcount >= 0) {
        region.pingColor = traffic(region?.avgtime, 10000, 2000);
        region.errorColor = traffic(
          region?.errcount / region?.reqcount,
          1,
          0.01
        );
        if (region.maintenance) region.statusColor = "steelblue";
        else region.statusColor = region.pingColor;
        const time = minDeltaMs * Math.trunc(lastrun / minDeltaMs);
        Vue.set(region.history, time, {
          time: new Date(time),
          avgtime,
          reqcount,
          color: traffic(avgtime, 10000, 2000)
        });
      }
      Vue.set(regions, key, region);
    },
    History({ regions }, { key, data }) {
      data?.forEach(({ time, avgtime, reqcount }) => {
        const date = new Date(time);
        return Vue.set(regions[key]?.history, +date, {
          time: date,
          avgtime,
          reqcount,
          color: traffic(avgtime, 10000, 2000)
        });
      });
    },
    Saas(state, value) {
      state.saas = value;
    },
    Capacity(state, value) {
      state.capacity = value;
    },
    DeltaMs(state, value) {
      value = minDeltaMs * Math.round(value / minDeltaMs);
      if (!value || value < minDeltaMs) value = minDeltaMs;
      state.deltaMs = value;
    }
  },
  getters: {
    overall: function(state) {
      let overall = Object.entries(state.regions ?? {}).reduce(
        ({ up, down, avgtime, reqcount, errcount, lastrun }, [_, region]) => ({
          up: up + (region?.up || 0),
          down: down + (region?.down || 0),
          reqcount: reqcount + (region?.reqcount || 0),
          errcount: errcount + (region?.errcount || 0),
          avgtime: avgtime + (region?.avgtime * region?.reqcount || 0),
          // convert lastrun to int with + to check for NaN
          // new Date(NaN) is truthy but +new Date(NaN) is falsy ¯\_(ツ)_/¯
          lastrun: Math.max(+lastrun, +region?.lastrun || 0)
        }),
        {
          up: 0,
          down: 0,
          avgtime: 0,
          reqcount: 0,
          errcount: 0,
          lastrun: 0
        }
      );
      overall.avgtime = overall.avgtime / overall.reqcount || undefined;
      overall.lastrun = new Date(
        minDeltaMs * Math.trunc(overall.lastrun / minDeltaMs)
      );
      overall.errrate = overall.errcount / overall.reqcount || 0;
      overall.pingColor = traffic(overall.avgtime, 10000, 2000);
      overall.errorColor = traffic(overall.errrate, 1, 0.01);
      overall.statusColor = overall.pingColor;
      return overall;
    }
  }
});
