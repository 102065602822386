<template lang="pug">
  div(v-if="saas")
    div
      .blk(title="How many seconds between each element in the chart (multiple of 5)")
        span
          | Delta:
        input(v-model.lazy.number="delta" type="number")
        FontAwesomeIcon.info(icon="info-circle")

</template>

<script>
import { mapActions, mapState } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Saas",
  components: {
    FontAwesomeIcon
  },
  methods: { ...mapActions(["DeltaMs"]) },
  computed: {
    ...mapState(["saas", "deltaMs"]),
    delta: {
      get() {
        return this.deltaMs / 1000;
      },
      set(val) {
        this.DeltaMs(1000 * val);
      }
    }
  }
};
</script>

<style scoped></style>
