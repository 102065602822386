<template lang="pug">
  Card
    template(v-slot:header)
      .flex
        .stats
          strong
            | Global Statistics
          .small(style="max-width:18em")
            | All measurements are for the last minute and are updated every {{ deltaMs / 1000 }} seconds.
        .ml-auto(:title="up" @mousedown="saasClicks")
          FontAwesomeIcon.fa-2x(icon="cloud" :color="statusColor")
    .fa-xs
      Saas
      .blk(title="Number of user interactions over the last minute")
        FontAwesomeIcon(:icon="['far', 'dot-circle']")
        |  - Operations: {{ reqcount }}
        FontAwesomeIcon.info(icon="info-circle")
      .blk(title="The average time over the last minute for each operation to complete")
        FontAwesomeIcon(icon="circle" :color="pingColor")
        |  - Avg. operation time: {{ avgtime }}
        FontAwesomeIcon.info(icon="info-circle")
      .blk(title="The percentage of operations that had an error over the last minute")
        FontAwesomeIcon(icon="circle" :color="errorColor")
        |  - Error rate: {{ errrate }}
        FontAwesomeIcon.info(icon="info-circle")
      .blk(title="The total number of errors over the last minute")
        FontAwesomeIcon(:icon="['far', 'circle']")
        |  - Errors: {{ errcount }}
        FontAwesomeIcon.info(icon="info-circle")
      .blk
        FontAwesomeIcon(:icon="['far', 'clock']")
        |  - Last Update: {{ lastrun }}
</template>

<script>
import Card from "./Card.vue";
import Saas from "./Saas.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faTimesCircle,
  faAngleUp,
  faAngleDown,
  faCircle,
  faCloud,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  faDotCircle as farDotCircle,
  faCircle as farCircle,
  faClock as farClock
} from "@fortawesome/free-regular-svg-icons";
import { mapGetters, mapMutations, mapState } from "vuex";

library.add(
  faCheckCircle,
  faCloud,
  faTimesCircle,
  faAngleUp,
  faAngleDown,
  farDotCircle,
  faCircle,
  farCircle,
  farClock,
  faInfoCircle
);

export default {
  name: "GlobalPanel",
  components: {
    Saas,
    Card,
    FontAwesomeIcon
  },
  data() {
    return {
      clicks: new Array(3),
      overall: {}
    };
  },
  methods: {
    ...mapMutations(["Saas"]),
    saasClicks(event) {
      this.clicks.push(event.timeStamp);
      this.clicks.shift();
      if (this.clicks[this.clicks.length - 1] - this.clicks[0] < 500) {
        this.clicks = new Array(3);
        this.Saas(!this.saas);
      }
    },
    update() {
      this.overall = this.storeOverall;
    }
  },
  computed: {
    ...mapGetters({ storeOverall: "overall" }),
    ...mapState(["saas", "deltaMs"]),
    statusColor() {
      return this.overall?.statusColor ?? "";
    },
    pingColor() {
      return this.overall?.pingColor ?? "";
    },
    errorColor() {
      return this.overall?.errorColor ?? "";
    },
    avgtime() {
      if (this.overall?.avgtime >= 0 && this.overall?.lastrun)
        return `${Math.floor(this.overall?.avgtime)} ms`;
      else return "No Data";
    },
    errrate() {
      if (this.overall?.errrate >= 0 && this.overall?.lastrun)
        return `${Math.floor(100000 * this.overall?.errrate) / 1000}%`;
      else return "No Data";
    },
    up() {
      if (
        this.overall?.up >= 0 &&
        this.overall?.down >= 0 &&
        this.overall?.lastrun
      )
        return `${this.overall?.up} out of ${this.overall?.up +
          this.overall?.down} pings were successful`;
      else return "No Data";
    },
    reqcount() {
      if (this.overall?.reqcount >= 0 && this.overall?.lastrun)
        return this.overall?.reqcount;
      else return "No Data";
    },
    errcount() {
      if (this.overall?.errcount >= 0 && this.overall?.lastrun)
        return this.overall?.errcount;
      else return "No Data";
    },
    lastrun() {
      if (+this.overall?.lastrun)
        return this.overall?.lastrun.toLocaleTimeString();
      else return "No Data";
    }
  },
  mounted() {
    this.update();
    // only updating once a second prevents glitchy looking updates
    setInterval(() => this.update(), 1000);
  }
};
</script>
