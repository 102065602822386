import { render, staticRenderFns } from "./RegionPanel.vue?vue&type=template&id=743aba02&scoped=true&lang=pug&"
import script from "./RegionPanel.vue?vue&type=script&lang=js&"
export * from "./RegionPanel.vue?vue&type=script&lang=js&"
import style0 from "./RegionPanel.vue?vue&type=style&index=0&id=743aba02&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "743aba02",
  null
  
)

export default component.exports