<template lang="pug">
  //- don't render until we have resized once and don't show until after resize render
  .chart(v-show="ready")
    template(v-if="clientWidth && dpr")
      svg(:width="clientWidth" :height="height" :viewBox="`0 0 ${clientWidth} ${height}`")
        defs
          //- mask across the top for text bar
          mask(id="mask")
            rect(rect x="0" :y="fontSize+2" :width="clientWidth" :height="height" style="fill:white;")
        text.t.l(id="reqcount" x=0 y=0 fill="blueviolet" :font-size="fontSize")
          | {{ reqText }}
        text.t.r(id="avgtime" :x="clientWidth" y=1 fill="limegreen" :font-size="fontSize")
          | {{ avgText }}
        text.b.l(x=0 :y="height" :font-size="fontSize")
          | {{ startTime }}
        text.b.r(:x="clientWidth" :y="height" :font-size="fontSize")
          | {{ endTime }}
        g(mask="url(#mask)")
          //- flip it and reverse it (translate the graph to an easier coordinate system)
          g(:transform="`matrix(1 0 0 -1 ${clientWidth - dataWidth + xScale} ${barHeight + fontSize + 6})`")
            template(v-for="e in normalized")
              line(:x1="e.x" :x2="e.x" :y1="0" :y2="e.ya * barHeight" :stroke="e.color" :stroke-width="xScale")
              circle(v-show="e.yr" :cx="e.x" :cy="e.yr * barHeight" :r="0.618 * xScale" :fill="e.cfill")
              line.overlay(:x1="e.x" :x2="e.x" :y1="-barHeight" :y2="2*barHeight" :stroke-width="2 * xScale")
                title
                  | {{ e.txt }}
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    history: { type: Object },
    lastrun: { type: Date }
  },
  data() {
    return {
      clientWidth: 0,
      dpr: 0, // device pixel ratio
      height: 80,
      fontSize: 10,
      responseTarget: 250,
      ready: false
    };
  },
  methods: {
    async onResize() {
      this.ready = false;
      this.clientWidth = this.$el.clientWidth;
      this.dpr = window.devicePixelRatio || 1;
      await this.$nextTick();
      this.ready = true;
    },
    top(data) {
      // get the max value up to 3 std from mean
      const avg = data.reduce((a, b) => a + b, 0) / data.length;
      const std = Math.sqrt(
        data.map(x => Math.pow(x - avg, 2)).reduce((a, b) => a + b, 0) /
          data.length
      );
      return Math.min(Math.max(...data), avg + 3 * std);
    },
    formatTime(time) {
      if (!+time) return "No Data";
      if (this.saas) return time.toLocaleString("en-us");
      return time.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "numeric"
      });
    },
    formatBarTime(time) {
      if (!+time) return "No Data";
      if (this.saas) return time.toLocaleString("en-us");
      return time.toLocaleTimeString("en-us", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      });
    }
  },
  computed: {
    ...mapState(["deltaMs", "capacity", "saas"]),
    data() {
      let now = +new Date();
      if (+this.lastrun && now - this.lastrun > this.deltaMs)
        now = this.lastrun;
      //
      // let latest = Math.max(...Object.keys(this.history));
      // if (latest && now - latest >= this.deltaMs)
      now = this.deltaMs * Math.floor(now / this.deltaMs);
      // const latest = this.lastrun;
      return Array(this.capacity)
        .fill()
        .map(
          (_, i) =>
            this.history[now - i * this.deltaMs] ?? {
              time: new Date(now - i * this.deltaMs)
            }
        )
        .reverse();
    },
    firstVisible() {
      return this.capacity - Math.round(this.clientWidth / (2 * this.xScale));
    },
    startTime() {
      return this.formatTime(this.data[this.firstVisible]?.time);
    },
    endTime() {
      return this.formatTime(this.data[this.data.length - 1]?.time);
    },
    barHeight() {
      return this.height - 2 * this.fontSize - 8;
    },
    normalized() {
      return this.data.map(({ avgtime, reqcount, color, time }, i) => {
        const r = reqcount >= 0;
        const a = avgtime > 0;
        let ret = {
          color: r || a ? color : "lightgray",
          cfill: "blueviolet",
          x: 2 * i * this.xScale + 1,
          yr: r ? reqcount / this.reqTop : 0,
          ya: a ? avgtime / this.avgTop : +!r
        };
        if (ret.yr > 1) {
          ret.yr = 1;
          ret.cfill = "slateblue";
        }
        let tooltip = [];
        if (r) tooltip.push(`Operations: ${reqcount}`);
        if (a) tooltip.push(`Avg. operation time: ${avgtime} ms`);
        if (!tooltip.length) tooltip.push("No Data");
        tooltip.push(this.formatBarTime(time));
        ret.txt = tooltip.join("\n");
        if (this.saas) ret.txt = { avgtime, reqcount, ...ret };
        return ret;
      });
    },
    xScale() {
      return Math.ceil(this.clientWidth / (2 * this.capacity));
    },
    reqTop() {
      return Math.max(
        25,
        this.top(
          this.data
            .slice(this.firstVisible)
            .map(({ reqcount }) => reqcount)
            .filter(x => x)
        )
      );
    },
    avgTop() {
      return Math.max(
        this.responseTarget,
        this.top(
          this.data
            .slice(this.firstVisible)
            .map(({ avgtime }) => avgtime)
            .filter(x => x)
        )
      );
    },
    dataWidth() {
      return 2 * this.xScale * this.capacity;
    },
    reqText() {
      const max = Math.max(
        0,
        ...this.data
          .slice(this.firstVisible)
          .map(({ reqcount }) => reqcount)
          .filter(x => x)
      );
      if (!max) return "No Data";
      if (this.clientWidth > 235) return `Max operations: ${max}`;
      if (this.clientWidth > 175) return `Max ops: ${max}`;
      if (this.clientWidth > 125) return `ops: ${max}`;
      return max;
    },
    avgText() {
      const max = Math.max(
        0,
        ...this.data
          .slice(this.firstVisible)
          .filter(x => x)
          .map(({ avgtime }) => avgtime)
          .filter(x => x)
      );
      if (!max) return "No Data";
      if (this.clientWidth > 265) return `Max response time: ${max} ms`;
      if (this.clientWidth > 200) return `Max response: ${max} ms`;
      if (this.clientWidth > 175) return `Max resp: ${max} ms`;
      if (this.clientWidth > 125) return `resp: ${max} ms`;
      return `${max} ms`;
    }
  },
  async mounted() {
    await this.$nextTick();
    await this.onResize();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  }
};
</script>

<style scoped>
text.t {
  dominant-baseline: text-before-edge;
  alignment-baseline: text-before-edge;
}

text.b {
  dominant-baseline: text-after-edge;
  alignment-baseline: text-after-edge;
}

text.l {
  text-anchor: start;
}

text.r {
  text-anchor: end;
}

line.overlay {
  stroke: black;
  stroke-opacity: 0;
}
</style>
