<template lang="pug">
  div(:id="region.key")
    Card
      template(v-slot:header)
        .flex(:title="saas?JSON.stringify(region, (k, v) => (k !== 'history' ? v : undefined), 2):false")
          .center.v-center(v-if="saas" style="margin-right: .5em")
            FontAwesomeIcon.fa-lg(icon="brush" :color="saasColor")
          .v-center
            div
              strong
                | {{ region.location }}
              br
              | {{ region.key }} ({{ region.status }})
          .ml-auto.v-center
            div
              .center
                FontAwesomeIcon.fa-2x(:icon="statusIcon" :title="health" :color="region.statusColor")
              .center(@click="toggle"  :style="planned?'color:#0000':''")
                FontAwesomeIcon.fa-lg(:class="planned?'':'clickable'" :icon="expanded?'angle-up':'angle-down'" :title="expanded?'Hide Stats':'Show Stats'")
      .fa-xs(v-show="expanded")
        .blk(v-if="saas")
          | Version:
          strong {{ region.version }}
        .blk
          | Status:
          strong {{ status }}
        .blk(title="Number of user interactions over the last minute")
          FontAwesomeIcon(:icon="['far', 'dot-circle']")
          |  - Operations: {{ reqcount }}
          FontAwesomeIcon.info(icon="info-circle")
        .blk(title="The average time over the last minute for each operation to complete")
          FontAwesomeIcon(icon="circle" :color="region.pingColor")
          |  - Avg. operation time: {{ avgTime }}
          FontAwesomeIcon.info(icon="info-circle")
        .blk(title="The percentage of operations that had an error over the last minute")
          FontAwesomeIcon(icon="circle" :color="region.errorColor")
          |  - Error rate: {{ errRate }}
          FontAwesomeIcon.info(icon="info-circle")
        .blk(title="The total number of errors over the last minute")
          FontAwesomeIcon(:icon="['far', 'circle']")
          |  - Errors: {{ errcount }}
          FontAwesomeIcon.info(icon="info-circle")
      template(v-slot:footer)
        StatusChart(:history="region.history" :lastrun="region.lastrun" :style="{'margin-top': expanded?0:'.5em'}")
</template>

<script>
import { mapState } from "vuex";
import StatusChart from "./StatusChart.vue";
import Card from "./Card.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faTimesCircle,
  faWrench,
  faClock,
  faQuestionCircle,
  faAngleUp,
  faAngleDown,
  faCircle,
  faBrush
} from "@fortawesome/free-solid-svg-icons";
import {
  faDotCircle as farDotCircle,
  faCircle as farCircle
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faCheckCircle,
  faTimesCircle,
  faWrench,
  faClock,
  faQuestionCircle,
  faAngleUp,
  faAngleDown,
  faCircle,
  faBrush,
  farDotCircle,
  farCircle
);

export default {
  name: "RegionPanel",
  components: {
    StatusChart,
    Card,
    FontAwesomeIcon
  },
  props: {
    region: Object
  },
  data() {
    return {
      expanded: undefined
    };
  },
  computed: {
    ...mapState(["saas"]),
    saasColor() {
      if (this.region.e2color === "blue") return "DeepSkyBlue";
      return this.region.e2color;
    },
    reqcount() {
      if (this.region.reqcount >= 0) return this.region.reqcount;
      else return "No Data";
    },
    errcount() {
      if (this.region.errcount >= 0) return this.region.errcount;
      else return "No Data";
    },
    avgTime() {
      if (this.region.avgtime >= 0) return `${this.region.avgtime} ms`;
      else return "No Data";
    },
    errRate() {
      if (this.region.errrate >= 0)
        return (100 * this.region.errrate).toFixed(3) + "%";
      else return "No Data";
    },
    health() {
      if (this.region.up >= 0 && this.region.down >= 0)
        return `${this.region.up} out of ${this.region.up +
          this.region.down} pings were successful`;
      else return "No Data";
    },
    planned() {
      return this.region?.status?.toUpperCase() === "PLANNED";
    },
    status() {
      if (this.region.maintenance) return "MAINTENANCE";
      if (this.region.up >= 0) return "UP";
      if (this.region.down >= 0) return "DOWN";
      return "No Data";
    },
    statusIcon() {
      if (this.region.maintenance) return "wrench";
      if (this.region.up) return "check-circle";
      if (this.region.down) return "times-circle";
      if (!this.region.live) return ["far", "clock"];
      return "question-circle";
    }
  },
  methods: {
    toggle() {
      if (!this.planned) this.expanded = !this.expanded;
      setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
    }
  },
  watch: {
    expanded() {
      localStorage.setItem(
        "e2-region-expand-" + this.region.key,
        this.expanded
      );
    }
  },
  created() {
    const expanded = localStorage.getItem(
      "e2-region-expand-" + this.region.key
    );
    if (expanded) this.expanded = JSON.parse(expanded);
    setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
