<template lang="pug">
  .wrap.header(v-if='errors.length')
    a(v-for="banner in banners" :key="banner.key" :href="'#'+banner.key")
      .inner.flex
        FontAwesomeIcon.fa-2x(icon="exclamation-circle")
        .content
          h4 {{ banner.title }}
          p {{ banner.text }}
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faExclamationCircle);

const phrases = {
  ping: "a higher than normal response time",
  rate: "a higher than normal error rate"
};

export default {
  name: "GlobalPanel",
  props: { errors: Array },
  components: {
    FontAwesomeIcon
  },
  computed: {
    banners() {
      return this.errors.map(events => {
        // build time string to display to user
        const time = new Date(events.starttime + "Z").toLocaleTimeString([], {
          timeStyle: "short"
        });
        // gather unique event types into set
        let types = new Set();
        let region;
        events.events.map(event => {
          types.add(event.type);
          region = event.region;
        });
        // get predefined phrases for each event type
        types = Array.from(types)
          .map(type => phrases[type?.toLowerCase()])
          .filter(p => p);
        // if no predefined phases, tell user something generic
        if (!types.length) types = ["an unexpected load"];
        return {
          key: region,
          text: `This issue started at ${time}. Engineers are working to resolve the issue.`,
          title: `${region} is experiencing ${types.join(" and ")}.`
        };
      });
    }
  }
};
</script>

<style scoped>
.content {
  margin-left: 1em;
}

.wrap {
  border-radius: 1em;
  padding: 0.25em;
  background-color: #dc3545;
}

.inner {
  padding: 0.5em;
  align-items: center;
}

h4,
p,
a {
  color: white;
  text-decoration: none;
  margin: 0;
}
</style>
