<template lang="pug">
  #e2shop-status.container
    Banner(:errors="errors")
    .row(:style="{'column-count': column_count}")
      .col
        StatusMap(:regions="regions")
      .col
        GlobalPanel
    .row(:style="{'column-count': column_count}")
      .col(v-for="region in panels")
        RegionPanel(:region="region" :key="region.key")
</template>

<script>
import StatusMap from "./components/StatusMap.vue";
import GlobalPanel from "./components/GlobalPanel.vue";
import RegionPanel from "./components/RegionPanel.vue";
import Banner from "./components/Banner.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "app",
  components: {
    StatusMap,
    GlobalPanel,
    RegionPanel,
    Banner
  },
  data() {
    return {
      column_count: 1
    };
  },
  methods: {
    ...mapActions(["Init"]),
    onResize() {
      const clientWidth = this.$el.clientWidth;
      this.column_count = 1 + (clientWidth >= 700) + (clientWidth >= 1300);
    }
  },
  computed: {
    ...mapState(["overall", "regions"]),
    errors() {
      return Object.entries(this.regions)
        .map(([_, { errstats }]) => errstats)
        .filter(x => x);
    },
    panels() {
      return Object.entries(this.regions).reduce((arr, [_, region]) => {
        if (region?.key) arr.push(region);
        return arr;
      }, []);
    }
  },
  async mounted() {
    this.onResize();
    await this.Init();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  }
};
</script>

<style>
.center {
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}

.v-center {
  display: flex;
  align-items: center;
}

.flex {
  display: -ms-flexbox;
  display: flex;
}

.small {
  font-size: small;
}

.info {
  margin-left: 0.5em;
  color: darkgray;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.row {
  column-gap: 0;
}

.col {
  break-inside: avoid-column;
  padding: 0.25em;
}

#e2shop-status {
  font-family: "Source Sans Pro", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: dimgray;
  line-height: 1.25em;
}

.blk {
  margin: 0.5em 0;
  font-size: 1.25em;
}
</style>
